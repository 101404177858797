import { apiGetService } from './baseApi.service';

export default function getSearch(
  query,
  page,
  itemsPerPage,
  topics,
  formats,
  audiences,
  languages
) {
  return (async () => {
    let url = `/api/materialsearch/search?page=${page}&itemsPerPage=${itemsPerPage}`;

    if (query) {
      url += `&query=${query}`;
    }

    if (topics) {
      url += `&topics=${topics}`;
    }

    if (formats) {
      url += `&formats=${formats}`;
    }

    if (audiences) {
      url += `&audiences=${audiences}`;
    }

    if (languages) {
      url += `&languages=${languages}`;
    }

    return apiGetService(url);
  })();
}
