import React, { useEffect, useState } from 'react';
import searchWhite from 'images/icons/layout/Search.svg';
import AnimateHeight from 'react-animate-height';
import closeBlue from 'images/icons/layout/Close-Blue.svg';
import getSearch from '@/services/materialSearch.service';
import getQueryStringParameters from '@/services/queryString.service';
import { Element, scroller } from 'react-scroll';
import ConditionalWrapper from '../shared/conditionalWrapper/ConditionalWrapper';
import MaterialListing from './MaterialListing';
import Pager from '../shared/pager/Pager';
import Loader from '../shared/loader/Loader';

export const handleScroll = (current) => {
  if (current.scrollTop > 0) {
    current.classList.add('shadow-start');
  } else {
    current.classList.remove('shadow-start');
  }
  if (current.scrollTop === current.scrollHeight - current.offsetHeight) {
    current.classList.remove('shadow-end');
  } else {
    current.classList.add('shadow-end');
  }
};

const getFilterValues = (items) =>
  items
    .filter((item) => item.isSelected)
    .map((item) => item.value)
    .join(',');

const mapItemsWithSelection = (items, selected) =>
  items?.map((item) => ({
    ...item,
    isSelected: selected?.split(',').includes(item.value)
  })) ?? [];

const MaterialLibrarySearchLanding = ({ noResultsHeader, noResultsText }) => {
  const placeholderText = React.useRef(null);
  const clearButton = React.useRef(null);
  const drawer = React.useRef(null);
  const [expanded, setExpanded] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [queryDisplay, setQueryDisplay] = useState('');
  const [resultCount, setResultCount] = useState(0);
  const [materials, setMaterials] = useState([]);
  const [topics, setTopics] = useState([]);
  const [formats, setFormats] = useState([]);
  const [audiences, setAudiences] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [page, setPage] = useState(1);
  const [pagerInfo, setPagerInfo] = useState();

  const [isTopicsExpanded, setIsTopicsExpanded] = useState(false);
  const [isFormatsExpanded, setIsFormatsExpanded] = useState(false);
  const [isAudiencesExpanded, setIsAudiencesExpanded] = useState(false);
  const [isLanguagesExpanded, setIsLanguagesExpanded] = useState(false);
  const [isClearDisabled, setIsClearDisabled] = useState(true);

  const getFilters = async (selectedFilters) => {
    const response = await getSearch('', 1, 1);

    const rawTopics = response?.filters.find((filter) => filter.label === 'MaterialTopics');
    const rawFormats = response?.filters.find((filter) => filter.label === 'MaterialFormat');
    const rawAudiences = response?.filters.find((filter) => filter.label === 'MaterialAudience');
    const rawLanguages = response?.filters.find((filter) => filter.label === 'MaterialLanguage');

    setTopics(
      mapItemsWithSelection(
        rawTopics.items?.filter((t) => t.value !== 'Material Library'),
        selectedFilters.topics
      )
    );
    setFormats(mapItemsWithSelection(rawFormats.items, selectedFilters.formats));
    setAudiences(mapItemsWithSelection(rawAudiences.items, selectedFilters.audiences));
    setLanguages(mapItemsWithSelection(rawLanguages.items, selectedFilters.languages));
  };

  const getMaterials = async (filters) => {
    setPage(filters.page);

    const response = await getSearch(
      filters.query ?? query,
      filters.page,
      20,
      filters.topicFilter,
      filters.formatFilter,
      filters.audienceFilter,
      filters.languageFilter
    );

    setQueryDisplay(filters.query ?? query);
    setResultCount(response?.listing?.pager?.totalItems ?? 0);
    setMaterials(response?.listing?.items ?? []);
    setPagerInfo(response?.listing?.pager);
  };

  const setUrl = (filters) => {
    const currentUrl = window.location.href.split('?')[0];

    const topicFilter = getFilterValues(filters.topics);
    const formatFilter = getFilterValues(filters.formats);
    const audienceFilter = getFilterValues(filters.audiences);
    const languageFilter = getFilterValues(filters.languages);

    const props = [
      filters.query && `query=${filters.query}`,
      topicFilter && `topics=${topicFilter}`,
      formatFilter && `formats=${formatFilter}`,
      audienceFilter && `audiences=${audienceFilter}`,
      languageFilter && `languages=${languageFilter}`,
      filters.page && filters.page !== 1 && `page=${filters.page}`
    ]
      .filter(Boolean)
      .join('&');

    window.history.pushState({}, 'Filter Update', `${currentUrl}${props ? `?${props}` : ''}`);

    getMaterials({
      topicFilter,
      formatFilter,
      audienceFilter,
      languageFilter,
      page: filters.page
    });
  };

  const updateTopics = (name, value) => {
    const updated = topics.map((t) => (t.value === name ? { ...t, isSelected: value } : t));
    setTopics(updated);
    setPage(1);
    setUrl({ query, topics: updated, formats, audiences, languages, page: 1 });
  };

  const updateFormats = (name, value) => {
    const updated = formats.map((f) => (f.value === name ? { ...f, isSelected: value } : f));
    setFormats(updated);
    setPage(1);
    setUrl({ query, topics, formats: updated, audiences, languages, page: 1 });
  };

  const updateAudiences = (name, value) => {
    const updated = audiences.map((a) => (a.value === name ? { ...a, isSelected: value } : a));
    setAudiences(updated);
    setPage(1);
    setUrl({ query, topics, formats, audiences: updated, languages, page: 1 });
  };

  const updateLanguages = (name, value) => {
    const updated = languages.map((l) => (l.value === name ? { ...l, isSelected: value } : l));
    setLanguages(updated);
    setPage(1);
    setUrl({ query, topics, formats, audiences, languages: updated, page: 1 });
  };

  const clearAllFilters = () => {
    setTopics(topics.map((t) => ({ ...t, isSelected: false })));
    setFormats(formats.map((f) => ({ ...f, isSelected: false })));
    setAudiences(audiences.map((a) => ({ ...a, isSelected: false })));
    setLanguages(languages.map((l) => ({ ...l, isSelected: false })));
    setUrl({ query, topics: [], formats: [], audiences: [], languages: [], page: 1 });
  };

  useEffect(() => {
    setIsClearDisabled(
      ![topics, formats, audiences, languages].some((arr) => arr.some((item) => item.isSelected))
    );
  }, [topics, formats, audiences, languages]);

  useEffect(() => {
    const { current } = drawer;
    if (!current || isLoading) return undefined;

    if (current.clientHeight < current.scrollHeight) {
      current.classList.add('shadow-end');
    } else {
      current.classList.remove('shadow-end');
    }

    const scroll = () => handleScroll(current);
    current.addEventListener('scroll', scroll);
    return () => {
      current.removeEventListener('scroll', scroll);
    };
  }, [isLoading]);

  useEffect(() => {
    if (expanded) {
      drawer.current.classList.add('expanded');
    } else {
      drawer.current.classList.remove('expanded');
    }
  }, [expanded]);

  const handleReset = () => {
    placeholderText.current.classList.remove('moved');
    clearButton.current.classList.remove('visible');
    setQuery('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setUrl({ query, topics, formats, audiences, languages, page: 1 });
  };

  const handleInput = (e) => {
    if (e.target.value) {
      placeholderText.current.classList.add('moved');
      clearButton.current.classList.add('visible');
      setQuery(e.target.value);
    } else {
      handleReset();
    }
  };

  useEffect(() => {
    getQueryStringParameters().then((queryStringParameters) => {
      const filterList = {};

      Object.keys(queryStringParameters).forEach((key) => {
        filterList[key] = queryStringParameters[key];
      });

      handleInput({ target: { value: filterList.query ?? '' } });
      getFilters(filterList);
      getMaterials({
        query: filterList.query ?? '',
        topicFilter: filterList.topics ?? '',
        formatFilter: filterList.formats ?? '',
        audienceFilter: filterList.audiences ?? '',
        languageFilter: filterList.languages ?? '',
        page: parseInt(filterList.page ?? '1', 10)
      }).finally(() => setIsLoading(false));
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="material-library material-search-landing section-white">
      {isLoading && <Loader />}
      <Element name="scroll" />
      <section className="container-fluid">
        <form className="search-input" onSubmit={handleSubmit} onReset={handleReset}>
          <label>
            <span ref={placeholderText} className="placeholder">
              Search for materials
            </span>

            <input onChange={handleInput} type="text" value={query} />
          </label>

          <input ref={clearButton} type="reset" className="clear-search" value="Clear" />
          <button type="submit" className="btn btn-no-style search-icon">
            <img src={searchWhite} alt="Search Icon" />
          </button>
        </form>
      </section>

      <section className="container-fluid listing">
        <div className="filter-drawer-wrapper">
          <aside className="filters" ref={drawer}>
            <div className="filters-main">
              <div className="mobile-header">
                <h2>Filters</h2>
                <button
                  type="button"
                  className="close-filters btn btn-no-style"
                  onClick={() => setExpanded(false)}
                >
                  <img src={closeBlue} alt="Close Icon" />
                </button>
              </div>

              <button
                type="button"
                onClick={() => clearAllFilters()}
                disabled={isClearDisabled}
                className="btn clear"
              >
                Clear All
              </button>

              <div className="filter topic">
                <h3>Topic</h3>

                <FilterList
                  filters={topics}
                  isFilterExpanded={isTopicsExpanded}
                  setIsFilterExpanded={setIsTopicsExpanded}
                  updateFilters={updateTopics}
                />
              </div>

              <div className="filter format">
                <h3>Format</h3>

                <FilterList
                  filters={formats}
                  isFilterExpanded={isFormatsExpanded}
                  setIsFilterExpanded={setIsFormatsExpanded}
                  updateFilters={updateFormats}
                />
              </div>

              <div className="filter audience">
                <h3>Audience</h3>

                <FilterList
                  filters={audiences}
                  isFilterExpanded={isAudiencesExpanded}
                  setIsFilterExpanded={setIsAudiencesExpanded}
                  updateFilters={updateAudiences}
                />
              </div>

              <div className="filter language">
                <h3>Language</h3>

                <FilterList
                  filters={languages}
                  isFilterExpanded={isLanguagesExpanded}
                  setIsFilterExpanded={setIsLanguagesExpanded}
                  updateFilters={updateLanguages}
                />
              </div>
            </div>

            <div className="mobile-footer">
              <button type="button" className="btn apply" onClick={() => setExpanded(false)}>
                Apply
              </button>
            </div>
          </aside>
        </div>

        <div className="results">
          <div className="pre">
            <button type="button" className="show-filters" onClick={() => setExpanded(true)}>
              Show Filters
            </button>
            {!isLoading && (
              <h2>{`${resultCount} results${queryDisplay && ` for "${queryDisplay}"`}`}</h2>
            )}
          </div>

          {!isLoading && resultCount === 0 && (
            <div className="no-results">
              <h2>{noResultsHeader}</h2>
              <p>{noResultsText}</p>
            </div>
          )}

          {materials.map((material) => (
            <MaterialListing {...material} key={`${material.materialTitle}|${material.formID}`} />
          ))}

          {pagerInfo && pagerInfo.totalPages > 0 && (
            <Pager
              search={(_, newPage) => {
                setUrl({ query, topics, formats, audiences, languages, page: newPage });
                scroller.scrollTo('scroll', {
                  smooth: true,
                  duration: 500,
                  delay: 150,
                  offset: -250
                });
              }}
              currentPage={page}
              pagerInfo={pagerInfo}
            />
          )}
        </div>
      </section>
    </div>
  );
};

const FilterList = ({ filters, isFilterExpanded, setIsFilterExpanded, updateFilters }) => (
  <ConditionalWrapper
    condition={filters.length > 6}
    wrapper={(children) => (
      <>
        <AnimateHeight
          duration={500}
          height={isFilterExpanded ? 'auto' : 248}
          className="expanded-filters-list dropdown"
        >
          {children}
        </AnimateHeight>

        <button
          type="button"
          onClick={() => setIsFilterExpanded((prev) => !prev)}
          className="btn btn-no-style show-all"
        >
          {isFilterExpanded ? 'Show Less' : 'Show All'}
        </button>
      </>
    )}
  >
    <ol>
      {filters.map((filter) => (
        <li key={filter.value}>
          <label>
            <input
              type="checkbox"
              checked={filter.isSelected ?? false}
              onChange={() => updateFilters(filter.value, !filter.isSelected)}
            />
            {filter.value}
          </label>
        </li>
      ))}
    </ol>
  </ConditionalWrapper>
);

export default MaterialLibrarySearchLanding;
